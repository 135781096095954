<!--suppress HtmlUnknownTarget -->
<template>
  <div>
    <p>В японском языке есть 2 слоговых азбуки - хирагана и катакана. На данной странице вы можете скачать прописи для
      них.</p>
    <p>Хирагана используется очень часто, а значит и учится легче, поэтому больше внимания лучше уделить катакане.
      Особенно слогам ソ (so), ン (n), シ (shi) и ツ (tsu), отличающихся направлением черт.</p>
    <table class="ui selectable table">
      <thead>
      <tr>
        <th>Азбука</th>
        <th class="right aligned">Ссылка</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Хирагана</td>
        <td class="right aligned">
          <a href="/static/files/hiragana.pdf" target="_blank">Скачать PDF</a>
        </td>
      </tr>
      <tr>
        <td>Катакана</td>
        <td class="right aligned">
          <a href="/static/files/katakana.pdf" target="_blank">Скачать PDF</a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    meta: {
      titleH1: 'Прописи: кана',
      titleHead: 'Прописи каны в pdf',
      description: 'Прописи хираганы и катаканы в PDF с порядком черт (японские азбуки)',
    },
    data() {
      return {}
    }
  }
</script>
