import Vue from 'vue';
import VueRouter from 'vue-router';
import VueSimplemde from 'vue-simplemde';
import App from './App.vue';
import routes from './routes';
import store from './store';

import 'semantic-ui-css/components/reset.min.css';
import 'semantic-ui-css/components/site.min.css';
import 'semantic-ui-css/components/segment.min.css';
import 'semantic-ui-css/components/menu.min.css';
import 'semantic-ui-css/components/accordion.min.css';
import 'semantic-ui-css/components/button.min.css';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/grid.min.css';
import 'semantic-ui-css/components/form.min.css';
import 'semantic-ui-css/components/input.min.css';
import 'semantic-ui-css/components/table.min.css';
import 'semantic-ui-css/components/item.min.css';
import 'simplemde/dist/simplemde.min.css';

Vue.use(VueRouter);
Vue.use(VueSimplemde);

const router = new VueRouter({
  routes,
  mode: 'history'
});
router.beforeEach((to, from, next) => {
  router.app.$store.commit('updateMeta', to.meta);
  next();
});
router.afterEach(() => {
  router.app.$emit('closeMobileMenu');
});

// noinspection JSUnusedGlobalSymbols
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
});
