import Vue from "vue";
import Vuex from "vuex";
import page from "./modules/page";

Vue.use(Vuex);

// noinspection JSUnresolvedVariable,ES6ModulesDependencies
export default new Vuex.Store({
  modules: {
    page
  },
  strict: process.env.NODE_ENV !== 'production',
});
