import Home from './pages/Home.vue';
import Kana from './pages/Kana.vue';
import Kanji from './pages/Kanji.vue';
import YomiKata from './pages/YomiKata.vue';
import Generator from './pages/Generator.vue';

export default [
  {
    path: '/',
    component: Home
  },
  {
    path: '/yomikata/',
    component: YomiKata
  },
  {
    path: '/kana/',
    component: Kana
  },
  {
    path: '/kanji/',
    component: Kanji
  },
  {
    path: '/generator/',
    component: Generator
  },
].map((item) => {
  // Регистрация меты из компонента
  item.meta = item.component.meta;
  return item
});
