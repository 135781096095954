<template>
  <div>
    <p>Приветствую, посетитель!</p>
    <p>
      Pro-kanji - это сборник материалов по японской письменности.
      Да, просто сборник.
      Здесь нет уроков или какого-либо путеводителя.
      Данные материалы создавались и продолжаются создаваться мной в процессе
      изучения языка.
      Особую благодарность хотелось бы выразить создателю шрифта <a
      href="http://www.nihilist.org.uk/" target="_blank">KanjiStrokeOrders</a>.
      Без него большинства материалов просто бы не было.
    </p>

    <h2>Основные разделы:</h2>
    <div class="ui items">
      <div class="item">
        <div class="content">
            <router-link class="header" to="/kana/">Прописи: кана</router-link>
          <div class="description">
            <p>Прописи японских азбук - хираганы и катаканы</p>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="content">
            <router-link class="header" to="/kanji/">Прописи: иероглифы</router-link>
          <div class="description">
            <p>Прописи японских иероглифов (kanji, кандзи)</p>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="content">
            <router-link class="header" to="/yomikata/">Как написать</router-link>
          <div class="description">
            <p>Приложение, показывающее, как пишется указанный текст от руки</p>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="content">
            <router-link class="header" to="/generator/">Генератор прописей</router-link>
          <div class="description">
            <p>Создание своих уникальных прописей в формате PDF</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    meta: {
      titleH1: 'Pro-kanji - изучаем японские иероглифы',
    },
    data() {
      return {
        msg: 'Home'
      }
    }
  }
</script>
