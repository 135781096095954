<!--suppress HtmlFormInputWithoutLabel, CheckTagEmptyBody -->
<template>
  <div>
    <p>Генератор позволяет создавать свои индивидуальные прописи иероглифов. Иероглифы в прописях выполнены в слиле "от
      руки" с указанием порядка написания черт.</p>

    <noscript>Для работы с генератором нужен JavaScript</noscript>

    <div class="ui form">
      <div class="field">
        <label for="top-col">Верхний колонтитул</label>
        <input type="text" id="top-col" v-model="generatorData.top_col">
      </div>
      <div class="field">
        <label for="font-type">Шрифт</label>
        <select id="font-type" v-model="generatorData.font">
          <option value="KanjiStrokeOrders">KanjiStrokeOrders (японский)</option>
          <option value="CNstrokeorder">CNstrokeorder (китайский)</option>
        </select>
      </div>
      <div class="field">
        <div class="ui checkbox">
          <input type="checkbox" id="use-md" class="hidden" v-model="generatorData.use_md">
          <label for="use-md">Использовать markdown-разметку</label>
        </div>
      </div>
      <div class="field">
        <div class="ui checkbox">
          <input type="checkbox" id="show-pages" class="hidden" v-model="generatorData.show_pages">
          <label for="show-pages">Отображать номера страниц</label>
        </div>
      </div>
      <div class="field">
        <label for="start-page">Начать отсчёт со страницы</label>
        <input type="number" id="start-page" min="1" step="1" v-model.number="generatorData.start_page">
      </div>

      <div v-for="(item, index) in generatorData.items">
        <div class="ui segments">
          <div class="ui top attached header">
            <h5 class="generator-item-header"># {{ index + 1 }}</h5>
            <div class="ui mini basic buttons control-buttons" v-if="generatorData.items.length > 1">
              <button @click="moveItemUp(item)" class="ui grey basic button" v-if="index > 0">
                &uarr;
              </button>
              <button @click="moveItemDown(item)" class="ui grey basic button"
                      v-if="index < generatorData.items.length - 1">
                &darr;
              </button>
              <button @click="deleteItem(item)" class="ui red basic button">
                &times;
              </button>
            </div>
          </div>
          <div class="ui segment two column grid">
            <div class="field column">
              <label :for="`item_${index}-kanji`">Иероглиф</label>
              <input type="text" :id="`item_${index}-kanji`" v-model="item.kanji">
            </div>
            <div class="field column">
              <label :for="`item_${index}-translate`">Значение</label>
              <input type="text" :id="`item_${index}-translate`" v-model="item.translate">
            </div>
            <div class="field column">
              <label :for="`item_${index}-on`">Онные чтения</label>
              <markdown-editor v-model="item.on_readings" :configs="mdeConfig"
                               v-if="generatorData.use_md"></markdown-editor>
              <textarea :id="`item_${index}-on`" v-model="item.on_readings" rows="3" v-else></textarea>
            </div>
            <div class="field column">
              <label :for="`item_${index}-kun`">Кунные чтения</label>
              <markdown-editor v-model="item.kun_readings" :configs="mdeConfig"
                               v-if="generatorData.use_md"></markdown-editor>
              <textarea type="text" :id="`item_${index}-kun`" v-model="item.kun_readings" rows="3" v-else></textarea>
            </div>
            <div class="field column">
              <label :for="`item_${index}-examples`">Примеры</label>
              <markdown-editor v-model="item.examples" :configs="mdeConfig"
                               v-if="generatorData.use_md"></markdown-editor>
              <textarea type="text" :id="`item_${index}-examples`" v-model="item.examples" rows="3" v-else></textarea>
            </div>
            <div class="field column">
              <label :for="`item_${index}-hint`">Примечание</label>
              <markdown-editor v-model="item.hint" :configs="mdeConfig" v-if="generatorData.use_md"></markdown-editor>
              <textarea type="text" :id="`item_${index}-hint`" v-model="item.hint" rows="3" v-else></textarea>
            </div>
          </div>
        </div>

        <button @click="addItem(item)" class="ui small grey basic button add">+</button>
      </div>
      <div class="ui segment" v-show="generatorLink.length">
        <p>
          Ссылка на файл прописей:
          <br><a href="#" :href="generatorLink" target="_blank">{{ generatorLink }}</a>
        </p>
        <p>Если не открылось автоматически, разрешите всплывющие окна для данного сайта.</p>
      </div>
      <button class="ui success button" @click="send()" :class="{'loading': blockSend}">Готово</button>
    </div>
  </div>
</template>

<script>
  import {API_URL} from '../vars';

  const generatorItemSample = {
    kanji: '',
    translate: '',
    on_readings: '',
    kun_readings: '',
    examples: '',
    hint: '',
  };

  export default {
    meta: {
      titleH1: 'Генератор прописей',
    },
    data() {
      return {
        generatorLink: '',
        blockSend: false,
        generatorData: {
          top_col: '',
          font: 'KanjiStrokeOrders',
          show_pages: false,
          start_page: 1,
          use_md: false,
          items: [
            Object.assign({}, generatorItemSample),
          ],
        },
        mdeConfig: {
          toolbar: ['bold', 'italic', 'guide'],
          status: false,
        }
      }
    },
    methods: {
      addItem(item) {
        this.generatorData.items.splice(
          this.generatorData.items.indexOf(item) + 1,
          0,
          Object.assign({}, generatorItemSample)
        );
      },
      deleteItem(item) {
        this.generatorData.items.splice(
          this.generatorData.items.indexOf(item),
          1
        );
      },
      moveItemUp(item) {
        this.moveItem(item, -1);
      },
      moveItemDown(item) {
        this.moveItem(item, 1);
      },
      moveItem(item, delta) {
        let items = this.generatorData.items.slice(0);
        const currentPosition = this.generatorData.items.indexOf(item);
        const newPosition = currentPosition + delta;
        const element = items[newPosition];
        items[newPosition] = item;
        items[currentPosition] = element;

        this.generatorData.items = items;
      },
      send() {
        if (this.blockSend) {
          return;
        }

        this.blockSend = true;
        fetch(`${API_URL}generator/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.generatorData)
        })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          this.blockSend = false;
          this.generatorLink = data.link;
          window.open(data.link, '_blank');
        }).catch((error) => {
          this.blockSend = false;
          alert('There has been a problem with your fetch operation: ' + error.message);
        });
      }
    }
  }
</script>

<!--suppress CssUnusedSymbol -->
<style>
  .ui.buttons.control-buttons {
    position: absolute;
    right: -2px;
    top: -1px;
    z-index: 100;
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ui.buttons.control-buttons .button {
    opacity: .7;
  }

  .ui.buttons.control-buttons .button:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ui.buttons.control-buttons .button:last-child {
    border-bottom-right-radius: 0;
  }

  .ui.buttons.control-buttons .ui.basic.button:focus,
  .ui.buttons.control-buttons .ui.basic.button:active,
  .ui.buttons.control-buttons .ui.basic.button:hover {
    /* Там в библиотеке тоже important :( */
    background-color: #eee !important;
    opacity: 1;
  }

  .generator-item-header {
    line-height: 27px;
    margin: 0 10px;
  }

  .ui.button.add {
    width: 100%;
    margin-bottom: 1rem;
  }

  .markdown-editor .CodeMirror {
    height: 150px;
    min-height: 100px;
  }
</style>
