// Данные для страницы

const state = {
  titleH1: '',
  titleHead: '',
  description: '',
};

const mutations = {
  updateMeta: (state, {titleH1, titleHead, description}) => {
    state.titleH1 = titleH1;
    state.titleHead = titleHead;
    state.description = description;

    document.title = state.titleHead || state.titleH1;
    document.querySelector('meta[name="description"]').setAttribute(
      "content",
      state.description || 'Pro-kanji - прописи каны и японских иероглифов'
    );
  }
};


export default {
  state,
  mutations
};
