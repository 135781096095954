<!--suppress CheckTagEmptyBody, HtmlFormInputWithoutLabel -->
<template>
  <div>
    <div v-if="opened">
      <div class="ui right right action input">
        <input type="number" v-model="fontSize" :min="minFontSize" step="1">
        <button class="ui negative basic button" @click="incrementFontSize(-5)">- 5</button>
        <button class="ui positive basic button" @click="incrementFontSize(5)">+ 5</button>
      </div>

      <div class="ui form">
        <div class="field">
          <textarea :style="{'font-size': `${fontSize}px`}" class="yomi-text"></textarea>
        </div>
      </div>
      <button class="ui secondary button" @click="closeModule()">Закрыть</button>
    </div>
    <div v-else>
      <p>Внимание! При отображении модуля будет загружен шрифт размером более 17 Мб</p>
      <button class="ui secondary button" @click="openModule()">Запустить</button>
    </div>
  </div>
</template>

<script>
  export default {
    meta: {
      titleH1: 'Как написать',
    },
    data() {
      return {
        opened: window.localStorage.getItem('ykOpened') || false,
        minFontSize: 12,
        fontSize: 72
      }
    },
    methods: {
      openModule() {
        this.opened = true;
        window.localStorage.setItem('ykOpened', '1');
      },
      closeModule() {
        this.opened = false;
        window.localStorage.removeItem('ykOpened');
      },
      incrementFontSize(delta) {
        let fontSize = this.fontSize + delta;

        if (fontSize < this.minFontSize) {
          fontSize = this.minFontSize;
        }

        this.fontSize = fontSize;
      }
    }
  }
</script>

<!--suppress CssNoGenericFontName -->
<style>
  @font-face {
    font-family: 'kanji-stroke-orders';
    src: url(/assets/fonts/KanjiStrokeOrders.ttf);
  }

  .ui.form textarea.yomi-text:not([rows]) {
    font-family: 'kanji-stroke-orders'; /* NOQA */
    height: 4em;
    margin: 20px 0;
    padding: 15px;
  }
</style>
