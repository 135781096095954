<!--suppress CheckTagEmptyBody -->
<template>
  <div id="app">
    <div>
      <div class="ui inverted top fixed menu computer tablet only grid">
        <div class="ui container">
          <router-link class="header logo" to="/">
            <img src="/assets/logo.svg" alt="Pro-kanji" height="30">
          </router-link>
          <router-link class="item" active-class="active" to="/yomikata/">Как написать</router-link>
          <router-link class="item" active-class="active" to="/kana/">Кана</router-link>
          <router-link class="item" active-class="active" to="/kanji/">Иероглифы</router-link>
          <router-link class="item" active-class="active" to="/generator/">Генератор прописей</router-link>
        </div>
      </div>
    </div>

    <div>
      <div class="ui inverted top fixed menu mobile only grid">
        <div class="item" @click="toggleMobileMenu()">
          <div class="bar-container">
            <div class="bar bar1"></div>
            <div class="bar bar2"></div>
            <div class="bar bar3"></div>
          </div>
        </div>
        <router-link class="header logo" to="/">
          <img src="/assets/logo.svg" alt="Pro-kanji" height="30">
        </router-link>
      </div>
    </div>

    <div v-show="openMobileMenu">
      <div class="ui inverted left fixed vertical menu overlay visible">
        <router-link class="header item" to="/">
          Pro-Kanji
        </router-link>
        <router-link class="item" active-class="active" to="/yomikata/">Как написать</router-link>
        <router-link class="item" active-class="active" to="/kana/">Кана</router-link>
        <router-link class="item" active-class="active" to="/kanji/">Иероглифы</router-link>
        <router-link class="item" active-class="active" to="/generator/">Генератор прописей</router-link>
      </div>
      <div class="menu-overlay" @click="toggleMobileMenu()"></div>
    </div>

    <div class="ui main text container">
      <h1 class="title">{{ $store.state.page.titleH1 }}</h1>
      <router-view></router-view>
    </div>

    <div class="ui inverted footer basic segment">
      <div class="ui center aligned container">
        <p>Все представленные на данном сайте ресурсы
          могут быть использованы любым не противоречащим
          законодательству Российской Федерации способом.</p>
        <p>@ 2017-2019</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'app',
    mounted() {
      this.$parent.$on('closeMobileMenu', () => {
        this.openMobileMenu = false;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      });
    },
    data() {
      return {
        openMobileMenu: false
      }
    },
    methods: {
      toggleMobileMenu() {
        this.openMobileMenu = !this.openMobileMenu;
      }
    }
  }
</script>

<style>
  #app {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .main.container {
    margin: 7em auto;
    flex: 1;
  }

  .logo {
    padding: 10px 10px 5px;
  }

  .ui.menu.fixed.left {
    z-index: 103;
  }

  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #eeeeeeee;
    z-index: 102;
  }

  .bar-container {
    cursor: pointer;
  }

  .bar {
    width: 20px;
    height: 3px;
    background-color: #ffffffe6;
    margin: 4px 0;
    transition: 0.4s;
  }
</style>
