<!--suppress HtmlUnknownTarget, CheckTagEmptyBody -->
<template>
  <div>
    <p>Есть несколько видов делений иероглифов по группам. Чаще всего используются деления по уровням JLPT и по годам
      обучения. Я буду использовать деление по годам обучения. Стоит отметить, что данная группировка очень сильно
      отличается от JLPT, но она на мой взгляд лучше упорядочена.</p>
      <div class="ui styled fluid accordion">
        <div class="title" @click="toggleAccordion()" :class="{'active': opened}">
          <i class="dropdown icon"></i>
          Схема 3+1+1
        </div>
          <div class="content" :class="{'active': opened}">
            <p>Как прописывать, конечно, ваше дело, просто хочу рассказать про схему, которой пользовался я сам. Для
              начала стоит уточнить, что прописывал я иероглифы 2 года назад, потом забросил (само изучение, продолжил
              около полугода назад), но то, что прописывал до сих пор помню на уровне написания.</p>
            <p>Итак, длина одной итерации цикла по данной схеме - 5 дней. Из них 3 на изучение, 1 выходной и 1 на
              повторение. И именно в таком порядке.</p>
            <p>3 - изучение. Первый день прописываем некоторое количество иероглифов по 3 строки. Сразу предостерегу -
              не стоит брать по 5-10 штук сразу, замучаетесь потом. Второй день прописываем по 3 строки тех, что были в
              первый день плюс по 3 строки новых. Третий, соответственно, по 3 строки из первых двух дней плюс по 3
              строки новых.</p>
            <p>1 - выходной. Даже не вспоминаем, что занимаемся прописыванием.</p>
            <p>1 - повторение. Дописываем всё что набрали за первые 3 дня до конца страницы. Для первого дня будет по 3
              строки, для второго по 6, для третьего - 9, поэтому лучше более лёгкие иероглифы оставить на 3 день.</p>
            <p>P.S. я прописывал не в самодельных прописях, а в тетради и за 1 полную строку я принимал 2 - одну с
              клетками размером 2x2 тетрадных плюс одна с клетками, равными тетрадным. Но на поаторение у меня для
              первого дняоставалась 1 полная строка, для второго и третьего 4 и 7 соответственно.</p>
          </div>
      </div>
    <h2>1 год</h2>
    <table class="ui selectable table">
      <thead>
      <tr>
        <th>Иероглифы</th>
        <th class="right aligned">Ссылка</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Весь 1 год</td>
        <td class="right aligned">
          <a href="/static/files/kanji_gakkou_1.pdf" target="_blank">Скачать PDF</a>
        </td>
      </tr>
      <tr>
        <td>一, 二, 三, 四, 五, 六, 七, 八, 九, 十, 百, 千, 上, 下, 左, 右, 中, 大, 小, 月</td>
        <td class="right aligned">
          <a href="/static/files/kanji_gakkou_1_1.pdf" target="_blank">Скачать PDF</a>
        </td>
      </tr>
      <tr>
        <td>日, 年, 早, 木, 林, 山, 川, 土, 空, 田, 天, 生, 花, 草, 虫, 犬, 人, 名, 女, 男</td>
        <td class="right aligned">
          <a href="/static/files/kanji_gakkou_1_2.pdf" target="_blank">Скачать PDF</a>
        </td>
      </tr>
      <tr>
        <td>子, 目, 耳, 口, 手, 足, 見, 音, 力, 気, 円, 入, 出, 休, 先, 夕, 本, 文, 字, 立</td>
        <td class="right aligned">
          <a href="/static/files/kanji_gakkou_1_3.pdf" target="_blank">Скачать PDF</a>
        </td>
      </tr>
      <tr>
        <td>学, 校, 村, 町, 森, 正, 水, 火, 玉, 王, 石, 竹, 糸, 貝, 車, 金, 雨, 赤, 青, 白</td>
        <td class="right aligned">
          <a href="/static/files/kanji_gakkou_1_4.pdf" target="_blank">Скачать PDF</a>
        </td>
      </tr>
      </tbody>
    </table>
    <h2>2 год</h2>
    <table class="ui selectable table">
      <thead>
      <tr>
        <th>Иероглифы</th>
        <th class="right aligned">Ссылка</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>数, 多, 万, 半, 形, 太, 細, 広, 長, 点, 丸, 交, 光, 角, 計, 直, 線, 矢, 弱, 少</td>
        <td class="right aligned">
          <a href="/static/files/kanji_gakkou_2_1.pdf" target="_blank">Скачать PDF</a>
        </td>
      </tr>
      <tr>
        <td>強, 高, 同, 親, 母, 父, 姉, 兄, 弟, 妹, 自, 友, 体, 毛, 頭, 顔, 首, 心, 時, 曜</td>
        <td class="right aligned">
          <a href="/static/files/kanji_gakkou_2_2.pdf" target="_blank">Скачать PDF</a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    meta: {
      titleH1: 'Прописи: иероглифы',
      titleHead: 'Прописи японских иероглифов в pdf',
      description: 'Прописи японских иероглифов (кандзи) в формате PDF с порядком черт',
    },
    data() {
      return {
        opened: false
      }
    },
    methods: {
      toggleAccordion() {
        this.opened = !this.opened;
      }
    }
  }
</script>
